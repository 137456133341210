import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycIcon = _resolveComponent("KycIcon")!
  const _component_a_radio = _resolveComponent("a-radio")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_radio_group = _resolveComponent("a-radio-group")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_Card = _resolveComponent("Card")!
  const _component_a_slider = _resolveComponent("a-slider")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_KycStepNavigation = _resolveComponent("KycStepNavigation")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_KycLayout = _resolveComponent("KycLayout")!

  return (_openBlock(), _createBlock(_component_KycLayout, {
    title: _ctx.t(`Last question`),
    subtitle1: _ctx.t(`Who is your target audience?`),
    subtitle2: 
      _ctx.t('We will base the suggested model creators from these answers.')
    ,
    class: "kyc-step-4"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        model: _ctx.formState,
        onFinish: _ctx.handleFinish,
        onFinishFailed: _ctx.handleFailed,
        loading: _ctx.updateBusinessProfileLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, {
            size: 24,
            direction: "vertical",
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: _ctx.t('Gender')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_form_item, {
                    name: "gender",
                    class: "block",
                    rules: [
              _ctx.makeRequiredRule(_ctx.t('This field is required.'), 'string', true),
            ]
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_radio_group, {
                        value: _ctx.formState.gender,
                        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.gender) = $event))
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_row, { gutter: [20, 20] }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.genderOptions, (gender) => {
                                return (_openBlock(), _createBlock(_component_a_col, {
                                  key: gender.value,
                                  xs: 12,
                                  md: 6
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_a_radio, {
                                      value: gender.value
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("span", null, [
                                          (gender.kycIconName)
                                            ? (_openBlock(), _createBlock(_component_KycIcon, {
                                                key: 0,
                                                name: gender.kycIconName
                                              }, null, 8, ["name"]))
                                            : _createCommentVNode("", true),
                                          _createTextVNode(" " + _toDisplayString(gender.label), 1)
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["value"])
                                  ]),
                                  _: 2
                                }, 1024))
                              }), 128))
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["value"])
                    ]),
                    _: 1
                  }, 8, ["rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t('Age')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_slider, {
                    name: "age",
                    value: _ctx.formState.age,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.age) = $event)),
                    marks: _ctx.ageSliderOptions,
                    min: 18,
                    max: 45,
                    step: null,
                    range: ""
                  }, null, 8, ["value", "marks"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t('Tell me more about your target audience')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputTextArea, {
                    name: "targetAudienceDescription",
                    value: _ctx.formState.targetAudienceDescription,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.targetAudienceDescription) = $event)),
                    placeholder: _ctx.t('Describe your target audience'),
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                  }, null, 8, ["value", "placeholder", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_KycStepNavigation, {
                hasPrev: _ctx.hasPrev,
                hasNext: _ctx.hasNext,
                onBack: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('back'))),
                onSkip: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('skip'))),
                skippable: _ctx.skippable,
                loading: _ctx.updateBusinessProfileLoading
              }, null, 8, ["hasPrev", "hasNext", "skippable", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "onFinish", "onFinishFailed", "loading"])
    ]),
    _: 1
  }, 8, ["title", "subtitle1", "subtitle2"]))
}