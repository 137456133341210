import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "complex-bg kyc-step-1-bg" }
const _hoisted_2 = { class: "complex-bg__icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutIcon, {
        name: "brushBlue",
        id: "brushBlue"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushYellow",
        id: "brushYellow"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "roundedRectangle",
        id: "roundedRectangle"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "ellipse",
        id: "ellipse"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "horizontalLines",
        id: "horizontalLines-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "briefcase",
        id: "briefcase"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "handshake",
        id: "handshake"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "laptop",
        id: "laptop"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-right-1"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "legal",
        id: "legal"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-1"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "cloche",
        id: "cloche"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "hanger",
        id: "hanger"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-right-2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "lips",
        id: "lips"
      })
    ])
  ]))
}