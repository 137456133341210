import { Ref, ref, watch } from "vue";
import throttle from "lodash/throttle";

const DEFAULT_RESET_DELAY_MS = 1000;

/**
 * A composable to watch any Ref variables,
 * and expose a boolean Ref indicating if it's done updating after some delay
 */
export const useDoneUpdatingTracker = (options: {
  watchedRef: Ref<any>;
  delay?: number;
}) => {
  /** Tracks if done updating after some amount of delay. */
  const isDoneUpdating = ref(false);

  watch(options.watchedRef, () => {
    isDoneUpdating.value = false;
  });

  watch(
    options.watchedRef,
    throttle(() => {
      isDoneUpdating.value = true;
    }, options.delay || DEFAULT_RESET_DELAY_MS)
  );

  return { isDoneUpdating };
};
