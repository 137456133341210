import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "kyc__navigation flex justify-between items-center" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_button = _resolveComponent("a-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      (_ctx.hasPrev)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            class: "secondary",
            size: "small",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back')))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Go Back")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", null, [
      (_ctx.skippable)
        ? (_openBlock(), _createBlock(_component_a_button, {
            key: 0,
            type: "primary",
            size: "small",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('skip'))),
            class: "kyc__navigation__btn-skip"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("Skip")), 1)
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_a_button, {
        type: "primary",
        size: "small",
        "html-type": "submit",
        loading: _ctx.loading
      }, {
        default: _withCtx(() => [
          (_ctx.hasNext)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t("Next")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.t("Finish")), 1))
        ]),
        _: 1
      }, 8, ["loading"])
    ])
  ]))
}