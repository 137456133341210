import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Card = _resolveComponent("Card")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_KycStepNavigation = _resolveComponent("KycStepNavigation")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_KycLayout = _resolveComponent("KycLayout")!

  return (_openBlock(), _createBlock(_component_KycLayout, {
    title: _ctx.t(`Let's get social!`),
    subtitle1: _ctx.t(`Share us your social media handles.`),
    subtitle2: _ctx.t(`Answering this will help us create better videos for you!`)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        model: _ctx.formState,
        onFinish: _ctx.handleFinish,
        onFinishFailed: _ctx.handleFailed,
        loading: _ctx.updateBusinessProfileLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, {
            size: 24,
            direction: "vertical",
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: _ctx.t(`What's your company website?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    name: "companyWebsiteUrl",
                    value: _ctx.formState.companyWebsiteUrl,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.companyWebsiteUrl) = $event)),
                    placeholder: "https://",
                    rules: [
              _ctx.makeRequiredRule(_ctx.t('This field is required. Put N/A if none.')),
            ]
                  }, null, 8, ["value", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t(`Do you have Facebook?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    name: "facebookUrl",
                    value: _ctx.formState.facebookUrl,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.facebookUrl) = $event)),
                    placeholder: "https://facebook.com/",
                    rules: [
              _ctx.makeRequiredRule(_ctx.t('This field is required. Put N/A if none.')),
            ]
                  }, null, 8, ["value", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t(`How about Instagram?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    name: "instagramUrl",
                    value: _ctx.formState.instagramUrl,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.instagramUrl) = $event)),
                    placeholder: "https://instagram.com/",
                    rules: [
              _ctx.makeRequiredRule(_ctx.t('This field is required. Put N/A if none.')),
            ]
                  }, null, 8, ["value", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t(`What's your Tiktok?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    name: "tiktokUrl",
                    value: _ctx.formState.tiktokUrl,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.tiktokUrl) = $event)),
                    placeholder: "https://tiktok.com/@",
                    rules: [
              _ctx.makeRequiredRule(_ctx.t('This field is required. Put N/A if none.')),
            ]
                  }, null, 8, ["value", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t(`Why are you ordering videos?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputCheckbox, {
                    name: "orderReasons",
                    value: _ctx.formState.orderReasons,
                    "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.orderReasons) = $event)),
                    multiple: true,
                    options: _ctx.orderReasonsOptions,
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'), 'array')],
                    vertical: ""
                  }, null, 8, ["value", "options", "rules"]),
                  _createVNode(_Transition, { name: "fade" }, {
                    default: _withCtx(() => [
                      (_ctx.useOtherOrderReason)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                            _createVNode(_component_InputText, {
                              label: _ctx.t('Other'),
                              name: "orderReasonOther",
                              value: _ctx.formState.orderReasonOther,
                              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.formState.orderReasonOther) = $event)),
                              placeholder: _ctx.t('Please specify'),
                              rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))],
                              class: "mt-8"
                            }, null, 8, ["label", "value", "placeholder", "rules"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_KycStepNavigation, {
                hasPrev: _ctx.hasPrev,
                hasNext: _ctx.hasNext,
                onBack: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('back'))),
                onSkip: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('skip'))),
                skippable: _ctx.skippable,
                loading: _ctx.updateBusinessProfileLoading
              }, null, 8, ["hasPrev", "hasNext", "skippable", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "onFinish", "onFinishFailed", "loading"])
    ]),
    _: 1
  }, 8, ["title", "subtitle1", "subtitle2"]))
}