import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxImageInput = _resolveComponent("CheckboxImageInput")!
  const _component_Card = _resolveComponent("Card")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_a_card = _resolveComponent("a-card")!
  const _component_InputPhoneNumber = _resolveComponent("InputPhoneNumber")!
  const _component_KycStepNavigation = _resolveComponent("KycStepNavigation")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_KycLayout = _resolveComponent("KycLayout")!

  return (_openBlock(), _createBlock(_component_KycLayout, {
    title: 
      _ctx.displayName ? _ctx.t('Hello {name}!', { name: _ctx.displayName }) : _ctx.t('Hello!')
    ,
    subtitle1: _ctx.t(`Let's get to know each other!`),
    subtitle2: _ctx.t(`Answering this will help us create better videos for you!`),
    class: "kyc-step-1"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        model: _ctx.formState,
        onFinish: _ctx.handleFinish,
        onFinishFailed: _ctx.handleFailed,
        loading: _ctx.updateBusinessProfileLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, {
            size: 24,
            direction: "vertical",
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_Card, {
                title: _ctx.t('Is your product a:')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CheckboxImageInput, {
                    options: _ctx.businessOptions,
                    name: "business",
                    value: _ctx.formState.business,
                    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.business) = $event)),
                    xs: 24,
                    md: 12,
                    "single-select": "",
                    rules: [
              _ctx.makeRequiredRule(_ctx.t('This field is required.'), 'string', true),
            ]
                  }, null, 8, ["options", "value", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t(`What's your brand name?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputText, {
                    name: "nickname",
                    value: _ctx.formState.nickname,
                    "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.nickname) = $event)),
                    placeholder: _ctx.t('Your nickname here'),
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                  }, null, 8, ["value", "placeholder", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t('What industry are you in?')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CheckboxImageInput, {
                    name: "industry",
                    options: _ctx.industryCheckboxImageOptions,
                    value: _ctx.formState.industry,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.industry) = $event)),
                    span: 24,
                    direction: "horizontal",
                    "single-select": "",
                    rules: [{ validator: _ctx.industryValidator, trigger: 'change' }],
                    onChangeCapture: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleIndustryChange('industry')))
                  }, null, 8, ["options", "value", "rules"]),
                  _createVNode(_component_a_card, { class: "kyc-step-1__other-industry-card" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_InputText, {
                        label: _ctx.t('Other'),
                        name: "otherIndustry",
                        value: _ctx.formState.otherIndustry,
                        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.formState.otherIndustry) = $event)),
                        placeholder: _ctx.t('Please specify'),
                        rules: [{ validator: _ctx.industryValidator, trigger: 'change' }],
                        onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.handleIndustryChange('otherIndustry')))
                      }, null, 8, ["label", "value", "placeholder", "rules"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t(`What's your phone number?`)
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputPhoneNumber, {
                    name: "phoneNumber",
                    value: _ctx.formState.phoneNumber,
                    "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.formState.phoneNumber) = $event)),
                    placeholder: _ctx.t('+63xxxxxxxxxx'),
                    rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
                  }, null, 8, ["value", "placeholder", "rules"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_KycStepNavigation, {
                hasPrev: _ctx.hasPrev,
                hasNext: _ctx.hasNext,
                onBack: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('back'))),
                onSkip: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('skip'))),
                skippable: _ctx.skippable,
                loading: _ctx.updateBusinessProfileLoading
              }, null, 8, ["hasPrev", "hasNext", "skippable", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "onFinish", "onFinishFailed", "loading"])
    ]),
    _: 1
  }, 8, ["title", "subtitle1", "subtitle2"]))
}