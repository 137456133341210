import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KycProduct = _resolveComponent("KycProduct")!
  const _component_InputCheckbox = _resolveComponent("InputCheckbox")!
  const _component_Card = _resolveComponent("Card")!
  const _component_CheckboxImageInput = _resolveComponent("CheckboxImageInput")!
  const _component_KycStepNavigation = _resolveComponent("KycStepNavigation")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_KycLayout = _resolveComponent("KycLayout")!

  return (_openBlock(), _createBlock(_component_KycLayout, {
    title: _ctx.t(`One more to go.`),
    subtitle1: _ctx.t(`Tell me about your product`),
    subtitle2: _ctx.t(`Answering this will help us create better videos for you!`)
  }, {
    default: _withCtx(() => [
      _createVNode(_component_FormGroup, {
        model: _ctx.formState,
        onFinish: _ctx.handleFinish,
        onFinishFailed: _ctx.handleFailed,
        loading: _ctx.updateBusinessProfileLoading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_space, {
            size: 24,
            direction: "vertical",
            class: "flex"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_KycProduct, {
                "product-id": _ctx.formState.productId,
                "onUpdate:product-id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formState.productId) = $event)),
                "product-description": _ctx.formState.productDescription,
                "onUpdate:product-description": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.formState.productDescription) = $event)),
                "industry-id": _ctx.industryId
              }, null, 8, ["product-id", "product-description", "industry-id"]),
              _createVNode(_component_Card, {
                title: _ctx.t('Choose an adjective(s) decribing your product.')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputCheckbox, {
                    name: "productAdjective",
                    value: _ctx.formState.productAdjectives,
                    "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.formState.productAdjectives) = $event)),
                    multiple: true,
                    options: _ctx.productAdjectives,
                    class: "custom-checkbox-buttons"
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_Card, {
                title: _ctx.t('What kind of feelings does your product evoke?')
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_CheckboxImageInput, {
                    name: "productFeelings",
                    value: _ctx.formState.productFeelings,
                    "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.formState.productFeelings) = $event)),
                    options: _ctx.productFeelingOptions,
                    xs: 12,
                    md: 8,
                    gutter: [32, 18]
                  }, null, 8, ["value", "options"])
                ]),
                _: 1
              }, 8, ["title"]),
              _createVNode(_component_KycStepNavigation, {
                hasPrev: _ctx.hasPrev,
                hasNext: _ctx.hasNext,
                onBack: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('back'))),
                onSkip: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('skip'))),
                skippable: _ctx.skippable,
                loading: _ctx.updateBusinessProfileLoading
              }, null, 8, ["hasPrev", "hasNext", "skippable", "loading"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["model", "onFinish", "onFinishFailed", "loading"])
    ]),
    _: 1
  }, 8, ["title", "subtitle1", "subtitle2"]))
}