import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute right-4 bottom-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_textarea = _resolveComponent("a-textarea")!
  const _component_FormItem = _resolveComponent("FormItem")!

  return (_openBlock(), _createBlock(_component_FormItem, {
    label: _ctx.$attrs.label,
    name: _ctx.$attrs.name,
    value: _ctx.$attrs.value,
    rules: _ctx.$attrs.rules,
    extra: _ctx.$attrs.extra,
    help: _ctx.$attrs.help,
    validateFirst: _ctx.$attrs.validateFirst,
    validateTrigger: _ctx.$attrs.validateTrigger
  }, {
    labelEnd: _withCtx(() => [
      _renderSlot(_ctx.$slots, "labelEnd")
    ]),
    default: _withCtx(() => [
      _createVNode(_component_a_textarea, _mergeProps(_ctx.$attrs, {
        rows: _ctx.rows,
        "on-blur": _ctx.blurHandler
      }), _createSlots({ _: 2 }, [
        _renderList(_ctx.$slots, (_, slot) => {
          return {
            name: slot,
            fn: _withCtx((scope) => [
              _renderSlot(_ctx.$slots, slot, _normalizeProps(_guardReactiveProps(scope || {})))
            ])
          }
        })
      ]), 1040, ["rows", "on-blur"]),
      (!!_ctx.$slots['lower-right'])
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _renderSlot(_ctx.$slots, "lower-right", _normalizeProps(_guardReactiveProps({ isDoneUpdating: _ctx.isDoneUpdating })))
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }, 8, ["label", "name", "value", "rules", "extra", "help", "validateFirst", "validateTrigger"]))
}