
import { defineComponent, onMounted, ref } from "vue";
import { NumericKycNavigationSteps } from "@/shared/types/KycNavigationSteps";
import KycStep1 from "@/shared/components/Kyc/KycStep1.vue";
import KycStep2 from "@/shared/components/Kyc/KycStep2.vue";
import KycStep3 from "@/shared/components/Kyc/KycStep3.vue";
import KycStep4 from "@/shared/components/Kyc/KycStep4.vue";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useRoute, useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import ModalPrompt, {
  ModalPromptActionType,
} from "@/shared/components/ModalPrompt.vue";
import LayoutIcon from "../../shared/components/Icons/LayoutIcon.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { UsersBusinessProfilePendingStepChoices } from "../../../__generated__/globalTypes";
import message from "@/shared/utils/message";
import KycStep1Background from "@/shared/components/Kyc/Backgrounds/KycStep1Background.vue";
import KycStep2Background from "@/shared/components/Kyc/Backgrounds/KycStep2Background.vue";
import KycStep3Background from "@/shared/components/Kyc/Backgrounds/KycStep3Background.vue";
import KycStep4Background from "@/shared/components/Kyc/Backgrounds/KycStep4Background.vue";
import { scrollToTop } from "@/shared/utils/browser";
import {
  analyticsTrackKycStart,
  analyticsTrackKycContinue,
  analyticsTrackKycSkip,
  analyticsTrackKycFinish,
} from "@/shared/utils/analytics";

const KYC_STEPS_COMPONENT: Array<{
  component: any;
  backgroundComponent: any;
  skippable?: boolean;
}> = [
  {
    component: KycStep1,
    backgroundComponent: KycStep1Background,
  },
  {
    component: KycStep2,
    backgroundComponent: KycStep2Background,
  },
  {
    component: KycStep3,
    backgroundComponent: KycStep3Background,
  },
  {
    component: KycStep4,
    backgroundComponent: KycStep4Background,
  },
];

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const isCheckingOut = String(route.query.isCheckingOut) === "true";

    const { kycCurrentStep, kycDone, fetchBusinessProfile } =
      useBusinessProfile();

    // Template refs
    const kycUnfinishedModal: any = ref(null);

    const kycUnfinishedModalActions: ModalPromptActionType[] = [
      {
        title: t("Let's get started"),
        type: "primary",
        action: () => {
          analyticsTrackKycStart();
          kycUnfinishedModal.value.closeModal();
        },
      },
    ];

    // State
    const currentStepIndex = ref(0);

    const currentStepComponent = computed(() => {
      return KYC_STEPS_COMPONENT[currentStepIndex.value];
    });

    const hasPrev = computed(() => {
      return currentStepIndex.value >= 0; // can go back to Cart
    });

    const hasNext = computed(() => {
      return currentStepIndex.value < KYC_STEPS_COMPONENT.length - 1;
    });

    const goToNextStep = () => {
      if (currentStepIndex.value < KYC_STEPS_COMPONENT.length - 1) {
        currentStepIndex.value++;

        analyticsTrackKycContinue(currentStepIndex.value);

        console.log("Next Step");
      } else {
        console.log("Finished KYC");
        handleFinishedKyc();
      }

      setTimeout(() => {
        scrollToTop();
      }, 50);
    };

    const goToPrevStep = () => {
      if (currentStepIndex.value > 0) {
        currentStepIndex.value--;
      } else {
        router.back();
      }

      setTimeout(() => {
        scrollToTop();
      }, 50);
    };

    const handleStepSkip = () => {
      analyticsTrackKycSkip(currentStepIndex.value);

      goToNextStep();
    };

    const handleSavedKycStep = () => {
      goToNextStep();
    };

    const handleFinishedKyc = async () => {
      analyticsTrackKycFinish();

      // Show message on finish KYC
      message["success"]({
        content: "Successfully saved KYC details.",
      });

      if (isCheckingOut) {
        router.push({ name: routeNames.cart });
      } else {
        router.push({ name: routeNames.profile });
      }
    };

    // Hooks
    onMounted(async () => {
      try {
        await fetchBusinessProfile();

        // Show Modal on unfinished KYC
        if (!kycDone.value && kycUnfinishedModal.value) {
          kycUnfinishedModal.value.openModal();
        }

        // Load Pending Step
        if (
          kycCurrentStep.value &&
          kycCurrentStep.value !== UsersBusinessProfilePendingStepChoices.DONE
        ) {
          currentStepIndex.value =
            NumericKycNavigationSteps[kycCurrentStep.value];
        }
      } catch (e) {
        console.error(e);
      }
    });

    return {
      t,
      kycDone,
      currentStepIndex,
      currentStepComponent,
      goToNextStep,
      goToPrevStep,
      handleSavedKycStep,
      handleStepSkip,
      hasPrev,
      hasNext,
      kycUnfinishedModal,
      kycUnfinishedModalActions,
    };
  },
  components: {
    KycStep1,
    KycStep2,
    KycStep3,
    KycStep4,
    PageLayout,
    ModalPrompt,
    LayoutIcon,
  },
});
