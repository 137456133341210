import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_ModalPrompt = _resolveComponent("ModalPrompt")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    class: "kyc",
    fullWidth: true
  }, {
    complexBackground: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStepComponent.backgroundComponent)))
    ]),
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentStepComponent.component), {
        skippable: _ctx.currentStepComponent.skippable,
        hasPrev: _ctx.hasPrev,
        hasNext: _ctx.hasNext,
        onSave: _ctx.handleSavedKycStep,
        onBack: _ctx.goToPrevStep,
        onSkip: _ctx.handleStepSkip
      }, null, 40, ["skippable", "hasPrev", "hasNext", "onSave", "onBack", "onSkip"])),
      _createVNode(_component_ModalPrompt, {
        ref: "kycUnfinishedModal",
        actions: _ctx.kycUnfinishedModalActions,
        title: _ctx.t('We want to know you more.')
      }, {
        icon: _withCtx(() => [
          _createVNode(_component_LayoutIcon, { name: "userModal" })
        ]),
        default: _withCtx(() => [
          _createVNode(_component_a_typography_paragraph, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t(
            "Tell us more about your brand and target audience so we can provide you more personalized videos"
          )), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["actions", "title"])
    ]),
    _: 1
  }))
}