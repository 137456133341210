
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import Card from "@/shared/components/Card.vue";
import KycStepNavigation from "@/shared/components/Kyc/KycStepNavigation.vue";
import KycLayout from "@/shared/components/Layouts/KycLayout.vue";
import { ageSliderOptions, genderOptions } from "@/shared/utils/kycOptions";
import { computed, defineComponent, reactive, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import KycIcon from "@/shared/components/Icons/KycIcon.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { UpdateBusinessProfileVariables } from "@/api/kyc/__generated__/UpdateBusinessProfile";
import { TargetGenderChoices } from "../../../../__generated__/globalTypes";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";

export default defineComponent({
  setup(_, { emit }) {
    const { t } = useI18n();
    const {
      businessProfile,
      saveBusinessProfile,
      updateBusinessProfileLoading,
    } = useBusinessProfile();

    // Form State
    const formState = reactive({
      gender: "",
      age: [18, 25],
      targetAudienceDescription: "",
    });

    const parsedFormState = computed(
      (): UpdateBusinessProfileVariables => ({
        input: {
          targetAudience: {
            targetAudienceDescription: formState.targetAudienceDescription,
            targetGender: formState.gender as TargetGenderChoices,
            targetMinAge: formState.age[0],
            targetMaxAge: formState.age[1],
          },
        },
      })
    );

    // Load values from API
    watchEffect(() => {
      if (businessProfile.value) {
        // Load Gender
        if (businessProfile.value.targetGender) {
          formState.gender = businessProfile.value.targetGender;
        }

        // Load Age
        if (
          businessProfile.value.targetMinAge &&
          businessProfile.value.targetMaxAge
        ) {
          formState.age = [
            businessProfile.value.targetMinAge,
            businessProfile.value.targetMaxAge,
          ];
        }

        // Load Audience Description
        if (businessProfile.value.targetAudienceDescription) {
          formState.targetAudienceDescription =
            businessProfile.value.targetAudienceDescription;
        }
      }
    });

    const handleFinish = async () => {
      try {
        console.log("Save KYC:");
        console.log(parsedFormState.value);

        await saveBusinessProfile(parsedFormState.value);
        emit("save");
      } catch (e) {
        console.error(e);
      }
    };

    const handleFailed = () => {
      console.log("Failed to submit form");
      console.log(formState);
    };

    return {
      t,
      handleFailed,
      handleFinish,
      formState,
      genderOptions,
      ageSliderOptions,
      updateBusinessProfileLoading,
    };
  },
  props: {
    hasPrev: Boolean,
    hasNext: Boolean,
    skippable: Boolean,
  },
  methods: {
    makeRequiredRule,
  },
  components: {
    KycStepNavigation,
    FormGroup,
    KycLayout,
    Card,
    KycIcon,
    InputTextArea,
  },
  emits: ["save", "back", "skip"],
});
