import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "complex-bg kyc-step-4-bg" }
const _hoisted_2 = { class: "complex-bg__icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutIcon, {
        name: "brushBlue",
        id: "brushBlue"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushYellow",
        id: "brushYellow"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsHalfHeightWhite",
        id: "dotsHalfHeightWhite-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-left-1"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "handshake",
        id: "handshake"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "megaphone",
        id: "megaphone"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "ellipse",
        id: "ellipse-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-left-2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsHalfHeightWhite",
        id: "dotsHalfHeightWhite-right-1"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsHalfHeightWhite",
        id: "dotsHalfHeightWhite-right-2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-right"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "laptop",
        id: "laptop"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "plane",
        id: "plane"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "target",
        id: "target"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "ellipse",
        id: "ellipse-right"
      })
    ])
  ]))
}