import { renderSlot as _renderSlot, resolveDynamicComponent as _resolveDynamicComponent, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "modal-prompt__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_modal, {
      class: _normalizeClass(["modal-prompt", _ctx.$attrs['class']]),
      visible: _ctx.visible,
      "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.visible) = $event)),
      centered: "",
      maskClosable: _ctx.maskClosable,
      closable: _ctx.closable,
      footer: null
    }, {
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "icon", {}, () => [
          (_ctx.icon && _ctx.ModalPromptIconMap[_ctx.icon])
            ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.ModalPromptIconMap[_ctx.icon]), {
                key: 0,
                class: _normalizeClass(["modal-prompt__icon", _ctx.icon])
              }, null, 8, ["class"]))
            : _createCommentVNode("", true)
        ]),
        _renderSlot(_ctx.$slots, "title", {}, () => [
          (_ctx.title)
            ? (_openBlock(), _createBlock(_component_a_typography_title, {
                key: 0,
                level: 5,
                class: "modal-prompt__title"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.title), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_1, [
          _renderSlot(_ctx.$slots, "default", {}, () => [
            (_ctx.content)
              ? (_openBlock(), _createBlock(_component_a_typography_paragraph, { key: 0 }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.content), 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ])
        ]),
        _renderSlot(_ctx.$slots, "footer", {}, () => [
          (!_ctx.isEmpty(_ctx.actions))
            ? (_openBlock(), _createBlock(_component_a_space, {
                key: 0,
                size: _ctx.actions.length > 2 ? 12 : 24,
                class: _normalizeClass(["modal-prompt__footer justify-center", [_ctx.actions.length > 2 && 'no-auto-width']])
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action, index) => {
                    return (_openBlock(), _createBlock(_component_a_button, {
                      key: `${action.title}-${index}`,
                      size: "small",
                      onClick: ($event: any) => (_ctx.handleAttemptAction(action?.action)),
                      type: action?.type
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(action.title), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick", "type"]))
                  }), 128))
                ]),
                _: 1
              }, 8, ["size", "class"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }, 8, ["class", "visible", "maskClosable", "closable"]),
    _renderSlot(_ctx.$slots, "trigger", { open: _ctx.openModal })
  ], 64))
}