
import { defineComponent, ref, PropType } from "vue";
import { useI18n } from "vue-i18n";
import attempt from "lodash/attempt";
import isEmpty from "lodash/isEmpty";
import { ButtonType } from "ant-design-vue/lib/button";
import {
  CommonAlertTypes as ModalPromptIconType,
  commonAlertIconsMap as ModalPromptIconMap,
} from "@/shared/utils/alert";

export type ModalPromptActionType = {
  title: string;
  type?: ButtonType;
  action?: () => void;
};

/**
 * Because this is a prompt modal do not allow closing without selecting any action.
 */
export default defineComponent({
  props: {
    title: String,
    icon: String as PropType<ModalPromptIconType>,
    content: String,
    maskClosable: Boolean, // default value is false
    closable: Boolean, // default value is false

    /**
     * actions is required because there's no way to close the modal but by action
     */
    actions: {
      type: Array as PropType<ModalPromptActionType[]>,
      required: true,
    },

    /**
     * do not assign static boolean properties
     * setting this to false will not allow closing the modal on action
     */
    autoCloseOnAction: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const visible = ref(false);

    const openModal = () => {
      visible.value = true;
    };

    const closeModal = () => {
      visible.value = false;
    };

    const handleAttemptAction = (fn) => {
      // use lodash's attempt method
      if (fn !== undefined) {
        attempt(fn);
      }

      if (props.autoCloseOnAction) {
        /**
         * automatically close prompt
         * props.autoCloseOnAction - can be used to programmatically disallow modal close
         */
        closeModal();
      }
    };

    return {
      t,
      visible,
      openModal,
      closeModal,
      handleAttemptAction,
      ModalPromptIconMap,
    };
  },
  methods: { isEmpty },
});
