import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "complex-bg kyc-step-2-bg" }
const _hoisted_2 = { class: "complex-bg__icons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LayoutIcon = _resolveComponent("LayoutIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_LayoutIcon, {
        name: "brushBlue",
        id: "brushBlue"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushYellow",
        id: "brushYellow"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "brushPink",
        id: "brushPink"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-left-1"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "roundedRectangle",
        id: "roundedRectangle-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "horizontalLines",
        id: "horizontalLines-left"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-left-2"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "dotsWhite",
        id: "dotsWhite-right"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "laptop",
        id: "laptop"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "web",
        id: "web"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "plane",
        id: "plane"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "twinkle3",
        id: "twinkle3-right-1"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "roundedRectangle",
        id: "roundedRectangle-right"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "horizontalLines",
        id: "horizontalLines-right"
      }),
      _createVNode(_component_LayoutIcon, {
        name: "ellipse",
        id: "ellipse"
      })
    ])
  ]))
}