
import CheckboxImageInput from "@/shared/components/Forms/CheckboxImageInput.vue";
import { UpdateBusinessProfileVariables } from "@/api/kyc/__generated__/UpdateBusinessProfile";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import Card from "@/shared/components/Card.vue";
import KycProduct from "@/shared/components/Kyc/Items/KycProduct.vue";
import KycStepNavigation from "@/shared/components/Kyc/KycStepNavigation.vue";
import KycLayout from "@/shared/components/Layouts/KycLayout.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { NONE_OF_THE_ABOVE } from "@/shared/composables/useProducts";
import {
  productAdjectives,
  productFeelingOptions,
} from "@/shared/utils/kycOptions";
import {
  capitalize,
  computed,
  defineComponent,
  reactive,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import {
  ProductFeelingChoices,
  ProductInformationInput,
} from "@/../__generated__/globalTypes";

export default defineComponent({
  setup(_, { emit }) {
    const { t } = useI18n();
    const {
      businessProfile,
      saveBusinessProfile,
      updateBusinessProfileLoading,
    } = useBusinessProfile();
    const industryId = computed(() => businessProfile.value?.industry?.id);

    // Form State
    const formState = reactive<ProductInformationInput>({
      productId: "",
      productDescription: "",
      productAdjectives: [] as string[],
      productFeelings: [] as ProductFeelingChoices[],
    });

    const parsedFormState = computed(
      (): UpdateBusinessProfileVariables => ({
        input: {
          productInformation: {
            ...formState,
            productId:
              !formState.productId || formState.productId === NONE_OF_THE_ABOVE
                ? null
                : formState.productId,
            productDescription:
              !formState.productId || formState.productId === NONE_OF_THE_ABOVE
                ? formState.productDescription
                : "",
          },
        },
      })
    );

    // Load values from API
    watchEffect(() => {
      if (businessProfile.value) {
        // Load Selected Product if same as old industry,
        // or no industry selected (loads all options)
        if (
          businessProfile.value.product &&
          (!businessProfile.value.industry ||
            businessProfile.value.product.industry?.id ===
              businessProfile.value.industry?.id)
        ) {
          formState.productId = businessProfile.value.product.id;
          formState.productDescription = "";
        }
        // Load Product Description
        else if (businessProfile.value.productDescription) {
          formState.productId = NONE_OF_THE_ABOVE;
          formState.productDescription =
            businessProfile.value.productDescription;
        }

        // Load Product Adjectives
        if (businessProfile.value.productAdjectives) {
          formState.productAdjectives = businessProfile.value.productAdjectives;
        }

        // Load Product Feelings
        if (businessProfile.value.productFeelings) {
          formState.productFeelings = businessProfile.value
            .productFeelings as ProductFeelingChoices[];
        }
      }
    });

    const handleFinish = async () => {
      try {
        console.log("Save KYC:");
        console.log(parsedFormState.value);

        await saveBusinessProfile(parsedFormState.value);
        emit("save");
      } catch (e) {
        console.error(e);
      }
    };

    const handleFailed = () => {
      console.log("Failed to submit form");
      console.log(formState);
    };

    return {
      t,
      handleFailed,
      handleFinish,
      formState,
      productAdjectives,
      productFeelingOptions,
      updateBusinessProfileLoading,
      industryId,
    };
  },
  props: {
    hasPrev: Boolean,
    hasNext: Boolean,
    skippable: Boolean,
  },
  components: {
    KycStepNavigation,
    FormGroup,
    KycLayout,
    Card,
    KycProduct,
    CheckboxImageInput,
    InputCheckbox,
  },
  methods: {
    capitalize,
    makeRequiredRule,
  },
  emits: ["save", "back", "skip"],
});
