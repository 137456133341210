
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import KycLayout from "@/shared/components/Layouts/KycLayout.vue";
import { computed, defineComponent, reactive, watch } from "vue";
import { useI18n } from "vue-i18n";
import InputText from "@/shared/components/Forms/InputText.vue";
import Card from "@/shared/components/Card.vue";
import KycStepNavigation from "@/shared/components/Kyc/KycStepNavigation.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { UpdateBusinessProfileVariables } from "@/api/kyc/__generated__/UpdateBusinessProfile";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import InputCheckbox from "@/shared/components/Forms/InputCheckbox.vue";
import { orderReasonsOptions } from "@/shared/utils/kycOptions";
import { OrderReason } from "@/../__generated__/globalTypes";

export default defineComponent({
  setup(_, { emit }) {
    const { t } = useI18n();
    const {
      businessProfile,
      saveBusinessProfile,
      updateBusinessProfileLoading,
    } = useBusinessProfile();

    // Form State
    const formState = reactive({
      companyWebsiteUrl: "",
      facebookUrl: "",
      instagramUrl: "",
      tiktokUrl: "",
      orderReasons: [] as string[],
      orderReasonOther: "",
    });

    // Parsed data for sending to API
    const parsedFormState = computed(
      (): UpdateBusinessProfileVariables => ({
        input: {
          socialMedia: {
            companyWebsiteUrl: formState.companyWebsiteUrl,
            facebookUrl: formState.facebookUrl,
            instagramUrl: formState.instagramUrl,
            tiktokUrl: formState.tiktokUrl,
            orderReasons: formState.orderReasons.filter(
              (item) => item !== "OTHER"
            ) as (OrderReason | null)[],
            orderReasonOther: useOtherOrderReason.value
              ? formState.orderReasonOther
              : "",
          },
        },
      })
    );

    const useOtherOrderReason = computed(() =>
      formState.orderReasons.includes("OTHER")
    );

    watch([businessProfile], () => {
      // Load values from API
      if (businessProfile.value) {
        // Load URLs
        const companyWebsiteUrl = businessProfile.value.companyWebsiteUrl;
        const facebookUrl = businessProfile.value.facebookUrl;
        const instagramUrl = businessProfile.value.instagramUrl;
        const tiktokUrl = businessProfile.value.tiktokUrl;

        formState.companyWebsiteUrl = companyWebsiteUrl ?? "";
        formState.facebookUrl = facebookUrl ?? "";
        formState.instagramUrl = instagramUrl ?? "";
        formState.tiktokUrl = tiktokUrl ?? "";

        formState.orderReasons = [
          ...(businessProfile.value.orderReasons || []),
        ] as OrderReason[];

        // Load "Other" Order Reasons
        if (businessProfile.value.orderReasonOther) {
          formState.orderReasonOther = businessProfile.value.orderReasonOther;
          formState.orderReasons.push("OTHER");
        }
      }
    });

    const handleFinish = async () => {
      try {
        console.log("Save KYC:");
        console.log(parsedFormState.value);

        await saveBusinessProfile(parsedFormState.value);
        emit("save");
      } catch (e) {
        console.error(e);
      }
    };

    const handleFailed = () => {
      console.log("Failed to submit form");
      console.log(formState);
    };

    return {
      t,
      handleFailed,
      handleFinish,
      formState,
      updateBusinessProfileLoading,
      useOtherOrderReason,
      orderReasonsOptions,
    };
  },
  props: {
    hasPrev: Boolean,
    hasNext: Boolean,
    skippable: Boolean,
  },
  methods: { makeRequiredRule },
  components: {
    KycStepNavigation,
    FormGroup,
    KycLayout,
    InputText,
    Card,
    InputCheckbox,
  },
  emits: ["save", "back", "skip"],
});
