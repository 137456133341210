
import { UpdateBusinessProfileVariables } from "@/api/kyc/__generated__/UpdateBusinessProfile";
import CheckboxImageInput from "@/shared/components/Forms/CheckboxImageInput.vue";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputPhoneNumber from "@/shared/components/Forms/InputPhoneNumber.vue";
import Card from "@/shared/components/Card.vue";
import KycStepNavigation from "@/shared/components/Kyc/KycStepNavigation.vue";
import KycLayout from "@/shared/components/Layouts/KycLayout.vue";
import { useBusinessProfile } from "@/shared/composables/useBusinessProfile";
import { businessOptions } from "@/shared/utils/kycOptions";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";
import isEmpty from "lodash/isEmpty";
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { ProductType } from "@/../__generated__/globalTypes";
import { useFilterOptions } from "@/shared/composables/useFilterOptions";

export default defineComponent({
  setup(_, { emit }) {
    const { t } = useI18n();

    // This value will be updated from API (nickname)
    const displayName = ref("");

    const {
      businessProfile,
      saveBusinessProfile,
      updateBusinessProfileLoading,
    } = useBusinessProfile();
    const { industryCheckboxImageOptions } = useFilterOptions();

    // Form State
    const formState = reactive({
      business: "",
      nickname: "",
      industry: "",
      otherIndustry: "",
      phoneNumber: "",
    });

    // Parsed data for sending to API
    const parsedFormState = computed(
      (): UpdateBusinessProfileVariables => ({
        input: {
          basicInformation: {
            industryId: formState.industry ? Number(formState.industry) : null,
            industryOther: formState.otherIndustry,
            productType: formState.business as ProductType,
            nickname: formState.nickname,
            phoneNumber: formState.phoneNumber,
          },
        },
      })
    );

    watch([businessProfile], () => {
      // Load values from API
      if (businessProfile.value) {
        // Values from API
        const productType = businessProfile.value.productType;
        const nickname = businessProfile.value.nickname;

        // Update displayName
        if (nickname) {
          displayName.value = nickname;
        }

        // Load Product Type
        if (productType) {
          formState.business = productType;
        }

        // Load Nickname
        formState.nickname = nickname ?? "";

        // Load Industry
        if (businessProfile.value.industry) {
          formState.industry = businessProfile.value.industry.id;
        }
        // Else, if "Other" industry
        else {
          formState.otherIndustry = businessProfile.value.industryOther;
        }

        // Load Order Reasons
        formState.phoneNumber = businessProfile.value.phoneNumber;
      }
    });

    // Custom Form Validators
    const industryValidator = async () => {
      if (
        isEmpty(formState.industry) &&
        isEmpty(formState.otherIndustry.trim())
      ) {
        return Promise.reject(
          t("Select an industry from the options, or specify one.")
        );
      }

      return Promise.resolve();
    };

    // Handlers
    const handleFinish = async () => {
      try {
        console.log("Save KYC:");
        console.log(parsedFormState.value);

        await saveBusinessProfile(parsedFormState.value);
        emit("save");
      } catch (e) {
        console.error(e);
      }
    };

    const handleFailed = () => {
      console.log("Failed to submit form");
      console.log(formState);
    };

    const handleIndustryChange = (
      changedItem: "industry" | "otherIndustry"
    ) => {
      if (changedItem == "industry") {
        formState.otherIndustry = "";
      } else {
        formState.industry = "";
      }
    };

    return {
      t,
      handleFailed,
      handleFinish,
      formState,
      displayName,
      businessOptions,
      industryCheckboxImageOptions,
      industryValidator,
      handleIndustryChange,
      businessProfile,
      updateBusinessProfileLoading,
    };
  },
  props: {
    hasPrev: Boolean,
    hasNext: Boolean,
    skippable: Boolean,
  },
  methods: {
    makeRequiredRule,
  },
  components: {
    KycStepNavigation,
    FormGroup,
    KycLayout,
    Card,
    CheckboxImageInput,
    InputText,
    InputPhoneNumber,
  },
  emits: ["save", "back", "skip"],
});
