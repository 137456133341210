
import { computed, defineComponent } from "vue";
import FormItem from "@/shared/components/Forms/FormItem.vue";
import { useDoneUpdatingTracker } from "@/shared/composables/useDoneUpdatingTracker";

export default defineComponent({
  setup(props, { emit, attrs }) {
    const blurHandler = (e) => {
      emit("on-custom-blur-handler", e.target.value);
    };

    const valueModel = computed(() => attrs.value);

    const { isDoneUpdating } = useDoneUpdatingTracker({
      watchedRef: valueModel,
      delay: props.updateTrackerDelay,
    });

    return { blurHandler, isDoneUpdating };
  },
  props: {
    labelEnd: { type: Object },
    rows: {
      default: 4,
    },
    updateTrackerDelay: Number,
  },
  emits: ["on-custom-blur-handler", "get-input-ref"],
  components: {
    FormItem,
  },
});
